import * as React from "react";
import * as Mui from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import toast from "react-hot-toast";

const ranges = [
  {
    type: 1,
    days: 7,
    label: "1w",
  },
  {
    type: 2,
    days: 31,
    label: "1m",
  },
  {
    type: 3,
    days: 95,
    label: "3m",
  },
  {
    type: 4,
    days: 183,
    label: "6m",
  },
  {
    type: 5,
    days: 365,
    label: "1y",
  },
  {
    type: 6,
    days: 365 * 2,
    label: "2y",
  },
  {
    type: 7,
    days: 365 * 3,
    label: "3y",
  },
  {
    type: 8,
    days: 365 * 5,
    label: "5y",
  },
  {
    type: 9,
    days: 365 * 10,
    label: "10y",
  },
  {
    type: 10,
    label: "all",
  },
];

export default function ProfitCahrt(props) {
  const { rows } = props;
  const [rangeType, setRangeType] = React.useState(1);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [options, setOptions] = React.useState({
    chart: {
      zooming: {
        type: "x",
      },
    },
    title: {
      text: "Profit or Loss over time",
      align: "left",
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? "Click and drag in the plot area to zoom in"
          : "Pinch the chart to zoom in",
      align: "left",
    },
    xAxis: {
      type: "date",
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d", this.value);
        },
      },
    },
    yAxis: {
      title: {
        text: "Profit/Loss ($)",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgb(199, 113, 243)"],
            [0.7, "rgb(76, 175, 254)"],
          ],
        },
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    tooltip: {
      split: true,
      formatter: function () {
        return [
          "<br>" + Highcharts.dateFormat("%b/%d/%Y", this.x) + "</br>",
        ].concat(
          this.points
            ? this.points.map(
                (point) =>
                  point.series.name + " <b>" + point.y.toFixed(2) + "($)</b>"
              )
            : []
        );
      },
    },

    series: [
      {
        type: "area",
        name: "Profit/Loss",
      },
    ],
  });

  React.useEffect(() => {
    if (rows.length) {
      // filter rows by range type
      let chartData = rows;
      if (rangeType === 11) {
        chartData = rows.filter((row) => {
          const rowDate = new Date(row.date);

          return rowDate >= fromDate && rowDate <= toDate;
        });
      }

      if (rangeType < 10) {
        const to = new Date();
        const from = new Date();
        from.setDate(to.getDate() - ranges[rangeType - 1].days);

        chartData = rows.filter((row) => {
          const rowDate = new Date(row.date);

          return rowDate >= from && rowDate <= to;
        });
      }

      const groupedData = chartData.reduce((acc, cur) => {
        const { date, netProfit } = cur;

        // Check if the date already exists in the accumulator
        if (!acc[date]) {
          acc[date] = 0; // Initialize if not present
        }

        // Sum the profit for the date
        acc[date] += netProfit * 1.0;

        return acc;
      }, {});

      const result = Object.entries(groupedData).map(([date, netProfit]) => [
        new Date(date).getTime(),
        netProfit,
      ]);

      setOptions((options) => ({
        ...options,
        series: [
          {
            type: "area",
            name: "Profit/Loss",
            data: result,
          },
        ],
      }));
    }
  }, [rows, rangeType, fromDate, toDate]);

  const handleRangeChange = React.useCallback((rangeType) => {
    setRangeType(rangeType);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Mui.Container maxWidth="xl" sx={{ marginY: 2, paddingY: 2 }}>
        <Mui.Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ paddingX: 1, paddingTop: 1 }}
        >
          <Mui.Grid container spacing={2}>
            {ranges.map((range, index) => (
              <Mui.Grid item xs key={index}>
                <Mui.Button
                  variant={range.type === rangeType ? "contained" : "outlined"}
                  onClick={() => handleRangeChange(range.type)}
                >
                  {range.label}
                </Mui.Button>
              </Mui.Grid>
            ))}
          </Mui.Grid>
          <Mui.Stack spacing={2}>
            <MobileDatePicker
              label="From"
              value={fromDate}
              onChange={(newValue) => {
                if (newValue.getTime() <= toDate.getTime()) {
                  setFromDate(newValue);
                  setRangeType(11);
                } else
                  toast.error("Please select the date before the to date!");
              }}
              renderInput={(params) => <Mui.TextField {...params} />}
            />
            <MobileDatePicker
              label="To"
              value={toDate}
              onChange={(newValue) => {
                if (newValue.getTime() > fromDate.getTime()) {
                  setToDate(newValue);
                  setRangeType(11);
                } else
                  toast.error("Please select the date after the from date!");
              }}
              renderInput={(params) => <Mui.TextField {...params} />}
            />
          </Mui.Stack>
        </Mui.Stack>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Mui.Container>
    </LocalizationProvider>
  );
}
