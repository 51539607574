import * as React from "react";
import * as Mui from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBestPairs } from "../redux/reducers/analyticsReducer";

import Navbar from "./Navbar";
import Chart from "./Chart";
import { ranges, getDate } from "../redux/reducers/mainReducer";

export default function Analytics(props) {
  const dispatch = useDispatch();
  const pairs = useSelector((state) => state.analytics.pairs);
  const [xsd, setXsd] = React.useState(3);
  const [r, setR] = React.useState(null);
  const [currentSymbols1, setCurrentSymbols1] = React.useState(null);
  const [currentSymbols2, setCurrentSymbols2] = React.useState(null);
  const [currentR1, setCurrentR1] = React.useState(0);
  const [currentR2, setCurrentR2] = React.useState(0);
  const [rangeType1, setRangeType1] = React.useState(ranges[4].type);
  const [rangeType2, setRangeType2] = React.useState(ranges[4].type);
  const [from1, setFrom1] = React.useState(
    new Date().setDate(new Date().getDate() - ranges[4].days)
  );
  const [from2, setFrom2] = React.useState(
    new Date().setDate(new Date().getDate() - ranges[4].days)
  );
  const [to1, setTo1] = React.useState(new Date());
  const [to2, setTo2] = React.useState(new Date());

  const handleXsdChange = React.useCallback((e) => {
    setXsd(e.target.value);
  }, []);

  const handleRChange = React.useCallback((e) => {
    setR(e.target.value);
  }, []);

  const handleBestPair1Click = React.useCallback((pair, correlation) => {
    setCurrentSymbols1(pair.split("-"));
    setCurrentR1(correlation);
  }, []);

  const handleBestPair2Click = React.useCallback((pair, correlation) => {
    setCurrentSymbols2(pair.split("-"));
    setCurrentR2(correlation);
  }, []);

  const handleRange1Change = React.useCallback((rangeType) => {
    setRangeType1(rangeType);

    let date = new Date();
    setTo1(getDate(date));
    date.setDate(date.getDate() - ranges[rangeType - 1].days);
    const from = rangeType === 10 ? "2000-10-10" : getDate(date);
    setFrom1(from);
  }, []);

  const handleRange2Change = React.useCallback((rangeType) => {
    setRangeType2(rangeType);

    let date = new Date();
    setTo2(getDate(date));
    date.setDate(date.getDate() - ranges[rangeType - 1].days);
    const from = rangeType === 10 ? "2000-10-10" : getDate(date);
    setFrom2(from);
  }, []);

  React.useEffect(() => {
    dispatch(
      getBestPairs({
        xsd: xsd,
        r: r,
      })
    );
  }, [dispatch, xsd, r]);

  React.useEffect(() => {
    if (pairs.length) {
      setCurrentSymbols1(pairs[0][0].pair.split("-"));
      setCurrentSymbols2(pairs[1][0].pair.split("-"));
    }
  }, [pairs]);

  const ChartMemo1 = React.useMemo(() => {
    return currentSymbols1 === null ? (
      <Mui.Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
        <Mui.CircularProgress />
      </Mui.Box>
    ) : (
      <Chart symbols={currentSymbols1} from={from1} to={to1} r={currentR1} />
    );
  }, [currentR1, currentSymbols1, from1, to1]);

  const ChartMemo2 = React.useMemo(() => {
    return currentSymbols2 === null ? (
      <Mui.Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
        <Mui.CircularProgress />
      </Mui.Box>
    ) : (
      <Chart symbols={currentSymbols2} from={from2} to={to2} r={currentR2} />
    );
  }, [currentR2, currentSymbols2, from2, to2]);

  return (
    <Mui.Box>
      <Navbar />

      <Mui.Container
        maxWidth="xl"
        sx={{
          marginY: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Mui.TextField
          label="XSD"
          id="outlined-size-small"
          type="number"
          defaultValue="3"
          size="small"
          value={xsd}
          onChange={handleXsdChange}
          sx={{
            paddingRight: 2,
          }}
        />

        <Mui.TextField
          label="Correlation"
          id="outlined-size-small"
          type="number"
          size="small"
          value={r}
          onChange={handleRChange}
        />
      </Mui.Container>

      <Mui.Container>
        <Mui.Grid container spacing={2}>
          {ranges.map((range, index) => (
            <Mui.Grid item xs key={index}>
              <Mui.Button
                variant={range.type === rangeType1 ? "contained" : "outlined"}
                onClick={() => handleRange1Change(range.type)}
              >
                {range.label}
              </Mui.Button>
            </Mui.Grid>
          ))}
        </Mui.Grid>
        {ChartMemo1}
      </Mui.Container>

      <Mui.Container maxWidth="xl" sx={{ marginY: 2 }}>
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Mui.Typography>{`Pairs which have xSD > 3 for 1 year AND 10 year time frame`}</Mui.Typography>
          <Mui.Typography>{`${pairs[0]?.length} pairs`}</Mui.Typography>
        </Mui.Box>

        <Mui.Divider sx={{ marginBottom: 2 }} />

        <Mui.Grid container spacing={2}>
          {pairs[0]?.map((pair, index) => (
            <Mui.Grid item xs key={index}>
              <Mui.Chip
                label={`${pair.pair} - ${pair.xsd1.toFixed(
                  2
                )}(1 year), ${pair.xsd2.toFixed(2)}(10 years)`}
                onClick={() =>
                  handleBestPair1Click(pair.pair, pair.correlation)
                }
              />
            </Mui.Grid>
          ))}
        </Mui.Grid>
      </Mui.Container>

      <Mui.Container>
        <Mui.Grid container spacing={2}>
          {ranges.map((range, index) => (
            <Mui.Grid item xs key={index}>
              <Mui.Button
                variant={range.type === rangeType2 ? "contained" : "outlined"}
                onClick={() => handleRange2Change(range.type)}
              >
                {range.label}
              </Mui.Button>
            </Mui.Grid>
          ))}
        </Mui.Grid>
        {ChartMemo2}
      </Mui.Container>

      <Mui.Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 2 }}>
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Mui.Typography>{`Pairs which have xSD > 3 for 1 year AND "all" time frame`}</Mui.Typography>
          <Mui.Typography>{`${pairs[1]?.length} pairs`}</Mui.Typography>
        </Mui.Box>
        <Mui.Divider sx={{ marginBottom: 2 }} />
        <Mui.Grid container spacing={2}>
          {pairs[1]?.map((pair, index) => (
            <Mui.Grid item xs key={index}>
              <Mui.Chip
                label={`${pair.pair} - ${pair.xsd1.toFixed(
                  2
                )}(1 year), ${pair.xsd2.toFixed(2)}(all)`}
                onClick={() =>
                  handleBestPair2Click(pair.pair, pair.correlation)
                }
              />
            </Mui.Grid>
          ))}
        </Mui.Grid>
      </Mui.Container>
    </Mui.Box>
  );
}
